import React, { lazy } from "react";
import { Switch, Route, Redirect } from "react-router";
// import { useAppContext } from "./AppContext/App.context";
import useLocalStorage from "./hooks/localStorage";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

import EditProfile from "./containers/EditProfile/EditProfile";

// import GoDaddy from "./containers/Domains/GoDaddy/GoDaddy";
const Login = lazy(() => import("./containers/Login/Login"));
const ResetPassword = lazy(() =>
  import("./containers/ResetPassword/ResetPassword")
);

const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));
// eslint-disable-next-line
const whyDidYouRender = require("@welldone-software/why-did-you-render");

const TeamList = lazy(() =>
  import("./containers/Settings/Teams/TeamList/TeamList")
);
const GeofenceList = lazy(() =>
  import("./containers/Settings/Geofence/GeofenceList/GeofenceList")
);
const AutoAllocation = lazy(() =>
  import("./containers/Settings/AutoAllocation/AutoAllocation")
);
const ResourceSchedule = lazy(() =>
  import("./containers/Settings/ResourceSchedule/ResourceSchedule")
);
const SupervisorList = lazy(() =>
  import("./containers/Settings/Supervisor/SupervisorList/SupervisorList")
);

const CustomerList = lazy(() =>
  import("./containers/Customers/List/CustomerList")
);
const ExtensionsList = lazy(() =>
  import("./containers/Extensions/ExtensionList")
);
const extensionDetail = lazy(() =>
  import("./containers/Extensions/ExtensionDetail")
);
const JobsScheduler = lazy(() =>
  import("./containers/JobsScheduler/JobsScheduler")
);
const OverviewAnalytics = lazy(() =>
  import("./containers/Analytics/OverviewAnalytics")
);
const ShiftDashboard = lazy(() =>
  import("./containers/Analytics/ShiftDashboard")
);
const JobAnalytics = lazy(() => import("./containers/Analytics/JobAnalytics"));
const ResourceAnalytics = lazy(() =>
  import("./containers/Analytics/ResourceAnalytics")
);
const CustomerAnalytics = lazy(() =>
  import("./containers/Analytics/CustomerAnalytics")
);
const RoleList = lazy(() =>
  import("./containers/Settings/Roles/RoleList/RoleList")
);
const ResourceApp = lazy(() =>
  import("./containers/Settings/ResourceAppSettings/ResourceAppSetting")
);
const RecurringList = lazy(() =>
  import("./containers/Settings/Recurring/RecurringList")
);
const RecurringTasks = lazy(() =>
  import("./containers/Settings/Recurring/RecurringTasks")
);
const ResourceList = lazy(() => import("./containers/Resources/List/List"));
const AddResource = lazy(() =>
  import("./containers/Resources/AddResource/AddResource")
);
const editResource = lazy(() =>
  import("./containers/Resources/AddResource/AddResource")
);
const AddGeofence = lazy(() =>
  import("./containers/Settings/Geofence/AddGeofence/AddGeofence")
);
const editGeofence = lazy(() =>
  import("./containers/Settings/Geofence/AddGeofence/AddGeofence")
);
const AddResourceSchedule = lazy(() =>
  import(
    "./containers/Settings/ResourceSchedule/AddResourceSchedule/AddResourceSchedule"
  )
);
const createTemplate = lazy(() =>
  import("./containers/Settings/Templates/CreateTemplate/CreateTemplate")
);
const templateList = lazy(() =>
  import("./containers/Settings/Templates/TemplateList/TemplateList")
);
const Notifications = lazy(() =>
  import("./containers/Settings/Notifications/List/NotificationList")
);
const EditNotification = lazy(() =>
  import("./containers/Settings/Notifications/AddNotification/AddNotifications")
);
const AddNotification = lazy(() =>
  import("./containers/Settings/Notifications/AddNotification/AddNotifications")
);
const EarningPricing = lazy(() =>
  import("./containers/Settings/Earning-Pricing/ListView/EarningList")
);
const BookingformList = lazy(() =>
  import("./containers/Settings/Booking Form/BookingformList")
);
const AddBookingform = lazy(() =>
  import("./containers/Settings/Booking Form/AddBookingTemplate")
);
const EditBookingform = lazy(() =>
  import("./containers/Settings/Booking Form/AddBookingTemplate")
);
const CustomerApp = lazy(() =>
  import("./containers/Settings/CustomerApp/CustomerApp")
);
const AddEarningPricing = lazy(() =>
  import("./containers/Settings/Earning-Pricing/AddEarningRule/AddEarningRule")
);
const EditEarningPricing = lazy(() =>
  import("./containers/Settings/Earning-Pricing/AddEarningRule/AddEarningRule")
);
const Billing = lazy(() => import("./containers/Settings/Billing/Billing"));
const AddRole = lazy(() =>
  import("./containers/Settings/Roles/AddRole/AddRole")
);
const editRole = lazy(() =>
  import("./containers/Settings/Roles/AddRole/AddRole")
);
const JobsList = lazy(() => import("./containers/Jobs/JobsList/JobsList"));
const routesList = lazy(() =>
  import("./containers/Routes/RoutesList/RoutesList")
);
const routesLogs = lazy(() =>
  import("./containers/Routes/RoutesList/RouteLogs")
);
const ApiKeyComponent = lazy(() =>
  import("./containers/Settings/ApiKey/ApiKey")
);
const ChangeLanguage = lazy(() =>
  import("./containers/Settings/LanguageManager/ChangeLanguage/ChangeLanguage")
);
const EditLanguage = lazy(() =>
  import("./containers/Settings/LanguageManager/UpdateLanguage/UpdateLanguage")
);
const Chat = lazy(() => import("./containers/Chat/Dashboard/Dashboard"));
const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/login">
        {useLocalStorage.getItem("isAuthenticated") ? (
          <Redirect to="/jobs/list"></Redirect>
        ) : (
          <Login></Login>
        )}
      </Route>
      <Route exact path="/">
        <Redirect to="/login"></Redirect>
      </Route>
      <Route exact path="/resetpassword">
        <ResetPassword></ResetPassword>
      </Route>

      <PrivateRoute exact path="/settings/teams/" component={TeamList} />
      <PrivateRoute exact path="/chat/" component={Chat} />
      <PrivateRoute
        exact
        path="/settings/supervisors/"
        component={SupervisorList}
      />
      <PrivateRoute exact path="/settings/geofence/" component={GeofenceList} />
      <PrivateRoute
        exact
        path="/settings/autoallocation/"
        component={AutoAllocation}
      />
      <PrivateRoute
        exact
        path="/settings/resourceschedule/"
        component={ResourceSchedule}
      />
      <PrivateRoute
        exact
        path="/settings/resourceschedule/add"
        component={AddResourceSchedule}
      />
      <PrivateRoute exact path="/patients/list" component={CustomerList} />
      <PrivateRoute exact path="/extensions/list" component={ExtensionsList} />
      <PrivateRoute
        exact
        path="/extensions/list/:extensionId"
        component={extensionDetail}
      />
      <PrivateRoute
        exact
        path="/analytics/overview"
        component={OverviewAnalytics}
      />
      <PrivateRoute
        exact
        path="/analytics/shiftdashboard"
        component={ShiftDashboard}
      />
      <PrivateRoute exact path="/analytics/job" component={JobAnalytics} />
      <PrivateRoute
        exact
        path="/analytics/resource"
        component={ResourceAnalytics}
      />
      <PrivateRoute
        exact
        path="/analytics/customer"
        component={CustomerAnalytics}
      />
      <PrivateRoute exact path="/scheduler/" component={JobsScheduler} />

      <PrivateRoute exact path="/resources/list" component={ResourceList} />
      <PrivateRoute exact path="/resources/add" component={AddResource} />
      <PrivateRoute
        exact
        path="/resources/edit/:resourceId"
        component={editResource}
      />
      <PrivateRoute
        exact
        path="/settings/geofence/add"
        component={AddGeofence}
      />
      <PrivateRoute exact path="/routes/list" component={routesList} />
      <PrivateRoute exact path="/routes/logs" component={routesLogs} />
      <PrivateRoute
        exact
        path="/settings/geofence/edit/:geofenceId"
        component={editGeofence}
      />
      <PrivateRoute
        exact
        path="/settings/templates/add/"
        component={createTemplate}
      />
      <PrivateRoute
        exact
        path="/settings/templates/edit/:templateId"
        component={createTemplate}
      />
      <PrivateRoute
        exact
        path="/settings/templates/list/"
        component={templateList}
      />
      <PrivateRoute
        exact
        path={["/settings/notifications", "/settings/notifications/:type"]}
        component={Notifications}
      />
      <PrivateRoute
        exact
        path="/settings/notifications/:type/add"
        component={AddNotification}
      />
      <PrivateRoute
        exact
        path={["/settings/billing", "/settings/billing/:trial_expire"]}
        component={Billing}
      />
      <PrivateRoute exact path="/settings/earning" component={EarningPricing} />
      <PrivateRoute
        exact
        path="/settings/customerapp"
        component={CustomerApp}
      />
      <PrivateRoute
        exact
        path="/settings/earning/add"
        component={AddEarningPricing}
      />
      <PrivateRoute
        exact
        path="/settings/earning/edit/:Id"
        component={EditEarningPricing}
      />
      <PrivateRoute exact path="/settings/pricing" component={EarningPricing} />
      <PrivateRoute
        exact
        path="/settings/pricing/add"
        component={AddEarningPricing}
      />
      <PrivateRoute
        exact
        path="/settings/pricing/edit/:Id"
        component={EditEarningPricing}
      />
      <PrivateRoute
        exact
        path="/settings/bookingform"
        component={BookingformList}
      />
      <PrivateRoute
        exact
        path="/settings/bookingform/add"
        component={AddBookingform}
      />
      <PrivateRoute
        exact
        path="/settings/bookingform/edit/:Id"
        component={EditBookingform}
      />
      <PrivateRoute
        exact
        path="/settings/notifications/:type/edit/:Id/:template?"
        component={EditNotification}
      />

      <PrivateRoute exact path="/settings/role/add" component={AddRole} />
      <PrivateRoute
        exact
        path="/settings/role/edit/:roleId"
        component={editRole}
      />
      <PrivateRoute exact path="/settings/roles/" component={RoleList} />
      <PrivateRoute
        exact
        path="/settings/recurring/"
        component={RecurringList}
      />
      <PrivateRoute
        exact
        path="/settings/resourceapp"
        component={ResourceApp}
      />
      <PrivateRoute
        exact
        path="/settings/recurring/:ruleId"
        component={RecurringTasks}
      />

      <PrivateRoute exact path="/jobs/list" component={JobsList} />
      <PrivateRoute
        exact
        path="/settings/language/changelanguage"
        component={ChangeLanguage}
      />
      <PrivateRoute
        exact
        path="/settings/language/edit/:languageId"
        component={EditLanguage}
      />
      <PrivateRoute
        exact
        path="/settings/apikey/"
        component={ApiKeyComponent}
      />
      <PrivateRoute exact path="/editprofile" component={EditProfile} />
      {/* <PrivateRoute exact path="/dashboard" component={Dashboard} /> */}
    </Switch>
  );
};

AppRoutes.whyDidYouRender = true;

export default AppRoutes;
