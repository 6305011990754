export const taskHistoryTypes: any = {
  "0": {
    name: "Default",
    type: 0,
    color: "#347C17",
    comment: "Job Status changed by resource",
  },
  "1": {
    name: "Image",
    type: 1,
    color: "#B1FB17",
    comment: "Image added by resource",
  },
  "2": {
    name: "Signature",
    type: 2,
    color: "#FDD017",
    comment: "Signature added by resource",
  },
  "3": {
    name: "Created",
    type: 3,
    color: "#FFCBA4",
    comment: "Job created by user",
  },
  "4": {
    name: "Updated",
    type: 4,
    color: "#B5A642",
    comment: "Job updated",
  },
  "5": {
    name: "Deleted",
    type: 5,
    color: "#493D26",
    comment: "Job deleted",
  },
  "6": {
    name: "Created by API",
    type: 6,
    color: "#F87217",
    comment: "Job created by API",
  },
  "7": {
    name: "Unassigned",
    type: 7,
    color: "#DC381F",
    comment: "Job unassigned",
  },
  "8": {
    name: "Assigned",
    type: 8,
    color: "#0041C2",
    comment: "Job assigned",
  },
  "9": {
    name: "Reassigned",
    type: 9,
    color: "#800517",
    comment: "Job reassigned",
  },
  "10": {
    name: "Assignment started",
    type: 10,
    color: "#C48793",
    comment: "Auto Assignment initiated.",
  },
  "11": {
    name: "Assignment Failed",
    type: 11,
    color: "#FCDFFF",
    comment:
      "Auto Assignment failed because No eligible resource available for the job",
  },
  "12": {
    name: "Assignment Retry",
    type: 12,
    color: "#E45E9D",
    comment: "Auto Assignment retried",
  },
  "13": {
    name: "Assignment Requests",
    type: 13,
    color: "#F433FF",
    comment: "Assignment requests to resources",
  },
  "14": {
    name: "Assignment Accepted",
    type: 14,
    color: "#C12869",
    comment: "Resource accepted the job",
  },
  "15": {
    name: "Assignment Rejected",
    type: 15,
    color: "#614051",
    comment: "Assignment rejected by resource",
  },
  "16": {
    name: "Started",
    type: 16,
    color: "#7D1B7E",
    comment: "Resource started the task",
  },
  "17": {
    name: "Completed",
    type: 17,
    color: "#B048B5",
    comment: "Resource completed the task",
  },
  "18": {
    name: "Failed",
    type: 18,
    color: "#9E7BFF",
    comment: "Resource changed job status to failed",
  },
  "19": {
    name: "Cancelled",
    type: 19,
    color: "#E238EC",
    comment: "Resource changed job status to cancelled",
  },
  "20": {
    name: "Notes",
    type: 20,
    color: "#E238EC",
    comment: "Notes updated",
  },
  "21": {
    name: "Template",
    type: 20,
    color: "#E238EC",
    comment: "Template updated",
  },
  "22": {
    name: "Arrived",
    type: 20,
    color: "#4051b5",
    comment: "Resource changed job status to arrived",
  },
  "23": {
    name: "Created",
    type: 23,
    color: "#4051b5",
    comment: "Job created by customer",
  },
  "24": {
    name: "Cancelled",
    type: 23,
    color: "#4051b5",
    comment: "Job cancelled by customer",
  },
  "25": {
    name: "Failed",
    type: 23,
    color: "#4051b5",
    comment: "Customer changed job status to failed",
  },
};
