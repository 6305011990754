import React, { useState, Fragment } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import headerDropdownService from "../../services/headerDropdown.service";
import { useTranslation } from "react-i18next";

const ChatUserTypeDropdown = (props: any) => {
  const { t, i18n } = useTranslation(["Header", "Common"]);
  const [selectedUserType, setUserType] = useState(0);

  const onUserTypeChange = (event: any) => {
    setUserType(event.target.value);
    const dropdownData = {
      type: "chatUserType",
      data: { chatUserType: event.target.value },
    };
    headerDropdownService.emit(dropdownData);
    //props.userTypeChanged(event.target.value)
  };
  const userTypeList = [
    { label: t("Common:Resource"), value: 0 },
    { label: t("Common:Customer"), value: 1 },
  ];
  const renderTeamList = () => {
    return (
      userTypeList.length > 0 &&
      userTypeList.map((team: any, index: any) => {
        return <MenuItem value={team.value}> {team.label}</MenuItem>;
      })
    );
  };
  return (
    <Fragment>
      {/* <img
        src={TeamDropdownIcon}
        alt="team drpdown"
        className={classes["dropdown-pre-icon"]}
      ></img> */}
      <Select
        MenuProps={{
          disableScrollLock: true,
        }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        disableUnderline={true}
        value={selectedUserType}
        onChange={onUserTypeChange}
      >
        {renderTeamList()}
      </Select>
    </Fragment>
  );
};
export default ChatUserTypeDropdown;
