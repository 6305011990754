export const extensionService = {
  getExtensionsList(reqData: any) {
    return {
      url: "integration/extensions/get",

      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  getExtensionsDetails(reqData: any) {
    return {
      url: "integration/extensions/get/details",

      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  activateExtension(reqData: any, operation?: number) {
    let url = "integration/user/extension/activate";
    return {
      url: url,
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },

  hubspotDeal(reqData: any) {
    return {
      url: "integration/extension/hubspot",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  deactivateExtension(reqData: any) {
    return {
      url: "integration/user/extension/remove",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  twilioDetails(reqData: any) {
    return {
      url: "integration/extension/twilio",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
  deactivateTwilio(reqData: any) {
    return {
      url: "integration/twilio/remove",
      data: reqData["reqBody"],
      headers: reqData["headers"],
    };
  },
};
