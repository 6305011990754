import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.scss";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
const DateTimePicker = (props: any) => {
  const curr = new Date();
  let currentDateTime: any = setHours(
    setMinutes(new Date(), curr.getMinutes()),
    curr.getHours()
  );
  const [startDate, setStartDate] = useState(null);
  const filterPassedTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return props.disablePast
      ? currentDate.getTime() < selectedDate.getTime()
      : true;
  };

  useEffect(() => {
    //  setShown(true)
    if (props.value) {
      let dateSelected: any = new Date(props.value);
      setStartDate(dateSelected);
    }
  }, [props.value]);
  return (
    <DatePicker
      //className="red-border"
      className={props.className || "outlined"}
      selected={startDate}
      onChange={(date: any) => {
        if (date < new Date() && props.disablePast) {
          setStartDate(currentDateTime);
          props.onChange(currentDateTime);
        } else {
          setStartDate(date);
          props.onChange(date);
        }
      }}
      filterTime={filterPassedTime}
      minDate={props.disablePast ? new Date() : null}
      //minTime={new Date()}
      timeIntervals={15}
      timeFormat="HH:mm"
      showTimeSelect={props.showTimeSelect}
      showTimeSelectOnly={props.showTimeSelectOnly}
      placeholderText={props.label}
      dateFormat={
        props.showTimeSelect
          ? props.showTimeSelectOnly
            ? "HH:mm"
            : "yyyy-MM-dd HH:mm"
          : "yyyy-MM-dd"
      }
    />
  );
};

export default DateTimePicker;
