export const languageList = [
  {
    id: 1,
    code: "en",
    name: "English",
  },
  {
    id: 2,
    code: "es",
    name: "Spanish",
  },
  {
    id: 3,
    code: "el",
    name: "Greek",
  },
];
export const languageObj: any = {
  "1": "en",
  "2": "es",
  "3": "el",
};

export const componentList = [
  // { name: "login", id: 1 },
  // { name: "register", id: 2 },
  // { name: "dashboard", id: 3 },
  { name: "Analytics", id: 24, displayName: "Analytics" },
  { name: "APIKey", id: 5, displayName: "API Key" },
  { name: "Autoallocation", id: 6, displayName: "Auto Allocation" },
  { name: "Billing", id: 30, displayName: "Billing" },
  { name: "Common", id: 4, displayName: "Common" },
  { name: "Createjob", id: 17, displayName: "Create Job" },
  { name: "Customerlist", id: 7, displayName: "Customer List" },
  { name: "EarningPricing", id: 25, displayName: "Earning & Pricing" },
  { name: "Geofence", id: 8, displayName: "Geofence" },
  { name: "Jobfilter", id: 21, displayName: "Job Filter" },
  { name: "Jobslist", id: 9, displayName: "Jobs List" },
  { name: "LanguageManager", id: 26, displayName: "Language Manager" },
  { name: "Notifications", id: 10, displayName: "Notifications" },
  { name: "Profile", id: 27, displayName: "Profile" },
  { name: "Reassign", id: 28, displayName: "Reassign Resource" },
  { name: "Recurring", id: 11, displayName: "Recurring" },
  { name: "Resourcelist", id: 12, displayName: "Resource List" },
  { name: "ResourceSchedule", id: 29, displayName: "Resource List" },
  { name: "Role", id: 13, displayName: "Role" },
  { name: "RoutesList", id: 22, displayName: "Route List" },
  { name: "Sidebar", id: 20, displayName: "Sidebar" },
  { name: "Supervisor", id: 14, displayName: "Supervisor" },
  { name: "TaskDetail", id: 23, displayName: "Task Detail" },
  { name: "Team", id: 15, displayName: "Team" },
  { name: "Template", id: 16, displayName: "Template" },
  { name: "Header", id: 18, displayName: "Top Bar" },
  { name: "Validation", id: 19, displayName: "Valdations" },
];
export const componentObj: any = {
  1: "Login",
  2: "Register",
  // 3: "dashboard",
  4: "Common",
  5: "APIKey",
  6: "Autoallocation",
  7: "Customerlist",
  8: "Geofence",
  9: "Jobslist",
  10: "Notifications",
  11: "Recurring",
  12: "Resourcelist",
  13: "Role",
  14: "Supervisor",
  15: "Team",
  16: "Template",
  17: "Createjob",
  18: "Header",
  19: "Validation",
  20: "Sidebar",
  21: "Jobfilter",
  22: "RoutesList",
  23: "TaskDetail",
  24: "Analytics",
  25: "EarningPricing",
  26: "LanguageManager",
  27: "Profile",
  28: "Reassign",
  29: "ResourceSchedule",
  30: "Billing",
};
