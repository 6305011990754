import moment from "moment";
export const defaultDateTimeFormat = "YYYY-MM-DD HH:mm";
export const defaultTimeForamt = "HH:mm";
export const defaultDateFormat = "YYYY-MM-DD";
const DateTimeFormatter = {
  convert12HoursFormatTo24Format(time: string) {
    return moment(time, "hh:mm A").format("HH:mm");
  },
  convertDateTimeToSpecificFormatInUtc(dateTime: Date, utcOffset?: string) {
    return moment(dateTime).utc().format(defaultDateTimeFormat);
  },
  convertDateTimeToSpecificFormat(
    dateTime: string,
    utcOffset?: string,
    format = defaultDateTimeFormat
  ) {
    if (utcOffset) {
      return moment.utc(dateTime).utcOffset(utcOffset).format(format);
    }
    return moment(dateTime).utc().format(format);
  },
  changeOnlyFormatInSameTimeZone(dateTime: any, format?: string) {
    return moment(dateTime).format(format ? format : defaultDateTimeFormat);
  },
  getTimzoneOffsetInHoursAndMinutes(timezoneOffset: number) {
    //eslint-disable-next-line
    let timezone_offset_min = timezoneOffset,
      offset_hrs: any = parseInt(Math.abs(timezone_offset_min / 60).toString()),
      offset_min: any = Math.abs(timezone_offset_min % 60),
      timezone_standard;

    if (offset_hrs < 10) offset_hrs = "0" + offset_hrs;

    if (offset_min < 10) offset_min = "0" + offset_min;

    // Add an opposite sign to the offset
    // If offset is 0, it means timezone is UTC
    if (timezone_offset_min < 0)
      timezone_standard = "-" + offset_hrs + ":" + offset_min;
    else if (timezone_offset_min > 0)
      timezone_standard = "+" + offset_hrs + ":" + offset_min;
    else if (timezone_offset_min == 0) timezone_standard = "Z";

    // Timezone difference in hours and minutes
    // String such as +5:30 or -6:00 or Z
    return timezone_standard;
  },

  getTimeFromDateTimeInSameTimeZone(
    dateTime: Date,
    format = defaultTimeForamt
  ) {
    return moment(dateTime).utc().format(format);
  },
  getValueInMilliSeconds(dateTime: any) {
    // console.log(moment(dateTime).utc());
    // console.log(moment(dateTime).utc().format(defaultDateTimeFormat));
    return moment(dateTime).valueOf();
  },
  getUTCStartAndEndTimeFromLocal(selectedDate: any) {
    let startDate = selectedDate;
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    startDate = this.convertDateTimeToSpecificFormatInUtc(startDate);
    let endDate: any = selectedDate;
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate = this.convertDateTimeToSpecificFormatInUtc(endDate);
    return { startDate, endDate };
  },
  getUtcFromLocal(selectedDate: any) {
    return moment(selectedDate).local().format("YYYY-MM-DD HH:mm");
  },
  getLocalTimzoneOffsetInHoursAndMinutes() {
    //eslint-disable-next-line
    let timezone_offset_min = new Date().getTimezoneOffset(),
      offset_hrs: any = parseInt(Math.abs(timezone_offset_min / 60).toString()),
      offset_min: any = Math.abs(timezone_offset_min % 60),
      timezone_standard;

    if (offset_hrs < 10) offset_hrs = "0" + offset_hrs;

    if (offset_min < 10) offset_min = "0" + offset_min;

    // Add an opposite sign to the offset
    // If offset is 0, it means timezone is UTC
    if (timezone_offset_min < 0)
      timezone_standard = "+" + offset_hrs + ":" + offset_min;
    else if (timezone_offset_min > 0)
      timezone_standard = "-" + offset_hrs + ":" + offset_min;
    else if (timezone_offset_min == 0) timezone_standard = "Z";

    // Timezone difference in hours and minutes
    // String such as +5:30 or -6:00 or Z
    return timezone_standard;
  },
  isBefore(date: any) {
    return moment(date).isBefore(new Date());
  },
};
export default DateTimeFormatter;
