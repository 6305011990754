import React, { useEffect, useState } from "react";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment-timezone";
import DateTimePicker from "../DateTimePicker/DateTimePicker";
import { TemplateDataTypeEnum } from "../../enum/Template.enum";
import {
  defaultTimeForamt,
  defaultDateFormat,
  defaultDateTimeFormat,
} from "../../utils/DateFormatter";

const DateTimePickerInput = (props: any) => {
  const [value, updateValue]: any = useState("");
  console.log(value);

  useEffect(() => {
    console.log(props.value);
    if (!props.value) {
      console.log(props.value);
      updateValue(props.value);
    } else {
      let newValue: any = "";
      switch (props.type) {
        case TemplateDataTypeEnum.DATE:
          newValue = new Date(props.value);
          break;
        case TemplateDataTypeEnum.DATE_TIME:
          newValue = new Date(props.value);
          break;
        case TemplateDataTypeEnum.TIME:
          newValue = new Date("01/01/1970 " + props.value);
          //  newValue.setHours
          break;
      }
      updateValue(newValue);
    }

    // updateValue(DateTimeFormatter.convertDateTimeToSpecificFormat(props.value,'',format));
  }, [props.value]);
  const valueChangeFromPicker = (data: any) => {
    let newValue = "";
    switch (props.type) {
      case TemplateDataTypeEnum.DATE:
        newValue = data.format(defaultDateFormat);
        break;
      case TemplateDataTypeEnum.DATE_TIME:
        newValue = data.format(defaultDateTimeFormat);
        break;
      case TemplateDataTypeEnum.TIME:
        newValue = data.format(defaultTimeForamt);
        break;
    }

    setTimeout(() => {
      updateValue(newValue);
    });
    props.onChange(newValue);
  };

  const renderPickers = () => {
    switch (props.type) {
      case TemplateDataTypeEnum.DATE:
        return (
          <DateTimePicker
            className={props.inputVariant}
            //clearable={props.showClear}
            name={props.name}
            label={props.label}
            value={value || null}
            onChange={(data: any) => {
              setTimeout(() => {
                updateValue(data);
              });

              props.onChange(data, defaultDateFormat);
            }}
          />
        );
      case TemplateDataTypeEnum.DATE_TIME:
        return (
          <DateTimePicker
            format="YYYY-MM-DD HH:mm"
            name={props.name}
            label={props.label}
            value={value || null}
            showTimeSelect={true}
            onChange={(data: any) => {
              setTimeout(() => {
                updateValue(data);
              });

              props.onChange(data, defaultDateTimeFormat);
            }}
          />
        );

      case TemplateDataTypeEnum.TIME:
        return (
          <DateTimePicker
            format="HH:mm"
            name={props.name}
            label={props.label}
            value={value || null}
            showTimeSelect={true}
            showTimeSelectOnly={true}
            onChange={(data: any) => {
              setTimeout(() => {
                updateValue(data);
              });

              props.onChange(data, defaultTimeForamt);
            }}
          />
        );
    }
  };
  return (
    <div className="input__box-container">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {renderPickers()}
      </MuiPickersUtilsProvider>
    </div>
  );
};
export default DateTimePickerInput;
