import React, { useState, Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useAppContext } from "../../AppContext/App.context";
import { useForm } from "react-hook-form";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Btn from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import EditProfileEndpoints from "./EditProfile.endpoints";
import useToast from "../../components/Toast/hooks/useToast";
import useLoader from "../../hooks/useLoader";
import useApiService from "../../services/api.service";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "-25px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "auto",
  },
  basicinfo: {
    padding: "12px",
  },
  item: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "0px !important",
  },
  pageheading: {
    fontSize: "22px",
  },
  root: {
    width: "100%",
    marginLeft: "5%",
  },
  btn: {
    margin: theme.spacing(2),
  },
}));

const DeleteAccount = () => {
  const { t, i18n } = useTranslation(["Profile", "Common"]);
  const classes = useStyles();
  const [radioValue, setRadio] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const [deletePassword, setDeletePassword] = useState("");
  const [appData]: any = useAppContext();
  const toaster = useToast();
  const loader: any = useLoader();
  const ApiService = useApiService();

  const [showOtherstextbox, handleOtherstextbox] = useState(false);
  const [otherReason, setOtherReason] = useState("");

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
    getValues: getValues3,
    reset: reset3,
  } = useForm({
    mode: "onChange",
  });

  const clearFields = () => {
    setOtherReason("");
    reset3({
      radioValue: "",
    });
  };
  const DeleteAPIpopup = (event: any) => {
    // event.preventDefault();
    if (radioValue) {
      //deleteProfile.current.handleClickOpen();
      setOpen(true);
      setError(false);
    } else {
      setHelperText(t("Profile:Please select an option."));
      setError(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const funcShowInput = (event: any) => {
    event.preventDefault();
    handleOtherstextbox(true);
    console.log("showOtherstextbox", showOtherstextbox);
  };

  const closeInput = () => {
    handleOtherstextbox(false);
  };

  const onDeleteConfirm = () => {
    // let reqBody: any = {
    //   api_key: apikey,
    // };
    if (!deletePassword.trim()) {
      toaster.addToast({
        message: t("Profile:Please enter your password"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    if (radioValue === "Other" && !otherReason.trim()) {
      toaster.addToast({
        message: t("Profile:Please enter valid reason"),
        timeout: 3000,
        type: "error",
      });
      return;
    }
    let reason: any;
    radioValue === "Other" ? (reason = otherReason) : (reason = radioValue);

    const headers = {
      access_token: appData.loginData.access_token,
    };
    const reqData = {
      reqBody: {
        password: deletePassword,
        reason: reason,
      },
      headers: headers,
    };
    loader.showLoader();
    return ApiService.put(EditProfileEndpoints.deleteAccount(reqData))
      .then((res: any) => {
        loader.hideLoader();
        // deleteProfile.current.handleClose();
        toaster.addToast({
          message: res.message,
          timeout: 3000,
          type: "success",
        });
        localStorage.clear();
        window.location.href = "/login";
      })
      .catch((err) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  const onDeleteDiscard = () => {
    // deleteProfile.current.handleClose();
    setDeletePassword("");
    handleClose();
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setRadio((event.target as HTMLInputElement).value);
    setHelperText("");
    setError(false);
  };
  return (
    <div className={classes.basicinfo}>
      <h2>{t("Profile:Delete Account")}</h2>

      <form
        className="handleDeleteAccount"
        onSubmit={handleSubmit3(DeleteAPIpopup)}
      >
        <p>
          {t(
            "Profile:We really value your feedback. Please help us understand your problem so that we can improve as a product."
          )}
        </p>
        <Grid
          container
          justify="center"
          className={classes.container}
          spacing={10}
        >
          <Grid item xs={12}>
            <FormControl component="fieldset" error={error}>
              <RadioGroup
                aria-label="gender"
                //required={true}
                name="deleteaccountoptions"
                value={radioValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="It costs too much"
                  control={<Radio color="primary" />}
                  label={t("Profile:It costs too much")}
                />
                <FormControlLabel
                  value="It doesn't fit my use case"
                  control={<Radio color="primary" />}
                  label={t("Profile:It doesn't fit my use case")}
                />
                <FormControlLabel
                  value="I found another platform which I like more"
                  control={<Radio color="primary" />}
                  label={t(
                    "Profile:I found another platform which I like more"
                  )}
                />
                <FormControlLabel
                  value="I am not happy with the service"
                  control={<Radio color="primary" />}
                  label={t("Profile:I am not happy with the service")}
                />
                <FormControlLabel
                  value="I was just exploring the platform"
                  control={<Radio color="primary" />}
                  label={t("Profile:I was just exploring the platform")}
                />
                <FormControlLabel
                  value="I want to restart later on"
                  control={<Radio color="primary" />}
                  label={t("Profile:I want to restart later on")}
                />

                <FormControlLabel
                  value={"Other"}
                  control={<Radio color="primary" />}
                  label={t("Profile:Other")}
                />
                {radioValue === "Other" ? (
                  <Input
                    type="text"
                    id={"otherReason"}
                    name={"otherReason"}
                    onchange={(evt: any) => {
                      setOtherReason(evt.target.value);
                    }}
                    value={otherReason}
                    label={t("Profile:Other Reason")}
                  ></Input>
                ) : (
                  ""
                )}
              </RadioGroup>

              <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container justify="center">
          <Btn
            className={classes.btn}
            type={"Submit"}
            btnText={t("Common:Submit")}
            //onClick={(event:any)=>{return DeleteAPIpopup(event)}}
          ></Btn>

          <Btn
            className={classes.btn}
            onClick={() => {
              clearFields();
              setHelperText("");
              setRadio("");
            }}
            color={"default"}
            type={"button"}
            btnText={t("Common:Cancel")}
          ></Btn>
        </Grid>
        <Suspense fallback={<h3>Loading&hellip;</h3>}>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {t("Profile:Delete Account")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t(
                  "Profile:Once you delete the account, all the account data will be deleted and can't be recovered. Are you sure you want to delete the account?"
                )}
              </DialogContentText>

              <Input
                type="text"
                id={"delete-password"}
                name={"delete-password"}
                onchange={(evt: any) => {
                  setDeletePassword(evt.target.value);
                }}
                value={deletePassword}
                label={t("Profile:Please enter your account's password")}
              ></Input>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={onDeleteConfirm} color="primary">
                        Confirm
                      </Button>
                      <Button onClick={onDeleteDiscard} color="primary">
                        Discard
                      </Button>
 */}
              <Btn
                className={classes.btn}
                type={"Submit"}
                btnText={t("Common:Confirm")}
                onClick={onDeleteConfirm}
              ></Btn>

              <Btn
                className={classes.btn}
                onClick={onDeleteDiscard}
                color={"default"}
                type={"button"}
                btnText={t("Common:Discard")}
              ></Btn>
            </DialogActions>
          </Dialog>
        </Suspense>
      </form>
    </div>
  );
};

export default DeleteAccount;
