import React, { useEffect, useState } from "react";
// import i18n from './i18n';
import AppRoutes from "./App.routing";
import "./App.scss";
import { withRouter } from "react-router";
import HeaderSidebarWrapper from "./components/HeaderSidebarWrapper/HeaderSidebarWrapper";
import Toast from "./components/Toast/Toast";
import ToastRight from "./components/ToastRight/ToastRight";
// import GoogleAutocompleteService from './utils/googleAutocompleteService';
// import { loadThirdPartyScript } from './utils/loadThirdPartyScript';
import appEndpoints from "./App.endpoints";
import useApiService from "./services/api.service";
import { useAppContext } from "./AppContext/App.context";
import * as actions from "./AppContext/actions/actions";
import PageLoader from "./components/pageLoader/pageLoader";
import useLocalStorage from "./utils/localStorage";
import { Socket } from "./utils/socket";
import { chatSocket } from "./utils/chatSocket";
import setVariables from "./utils/setVariables";

import { useTranslation } from "react-i18next";

if (process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}
const exclusionArray = [
  "/login",
  "/register",
  "/step1",
  "/step2",
  "/resetpassword",
];

function App({ location }: any) {
  const [appData, dispatch]: any = useAppContext();
  const { t, i18n } = useTranslation();
  // let [, setShowChatDropdown] = useState(false);

  const ApiService: any = useApiService();
  const isHeaderSidemenuVisible = exclusionArray.indexOf(location.pathname) < 0;
  const [test, setTest] = useState(!!useLocalStorage.getItem("isSet"));
  useEffect(() => {
    setTest(true);

    const loginViaAccessToken = async () => {
      const data = {
        access_token: appData.loginData.access_token,
      };
      const res = await ApiService.post(appEndpoints.loginViaAccessToken(data));
      dispatch(actions.loginViaAccessToken(res.data));
    };

    if (appData.isAuthenticated && isHeaderSidemenuVisible) {
      Socket();
      chatSocket();
      // loginViaAccessToken();
    }
    return () => {
      // disconnectSocket();
    };

    // eslint-disable-next-line
  }, []);

  const changeHandler = (ev: any) => {
    i18n.changeLanguage(ev);
  };
  useEffect(() => {
    console.log(appData, "lopll");
  });

  return test ? (
    <div className={isHeaderSidemenuVisible ? "app" : ""}>
      {appData.onLoaded?.showLoader ? <PageLoader /> : null}
      {isHeaderSidemenuVisible ? (
        <HeaderSidebarWrapper></HeaderSidebarWrapper>
      ) : null}

      <div className={isHeaderSidemenuVisible ? "app__outer" : ""}>
        <AppRoutes></AppRoutes>
      </div>
      <Toast></Toast>
      <ToastRight></ToastRight>
    </div>
  ) : null;
}

App.whyDidYouRender = true;

export default withRouter(App);
