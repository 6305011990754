export const BusinessUsecase = (t: any) => {
  return [
    {
      use_case: 1,
      job_name: t("Profile:Food Delivery"),
    },
    {
      use_case: 2,
      job_name: t("Profile:Grocery Delivery"),
    },

    {
      use_case: 3,
      job_name: t("Profile:Pick up & Delivery"),
    },

    {
      use_case: 4,
      job_name: t("Profile:Courier"),
    },

    {
      use_case: 5,
      job_name: t("Profile:Laundry"),
    },

    {
      use_case: 6,
      job_name: t("Profile:Home Services"),
    },
    {
      use_case: 7,
      job_name: t("Profile:Field force Management"),
    },
    {
      use_case: 8,
      job_name: t("Profile:Others"),
    },
  ];
};
