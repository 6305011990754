import React, { useEffect, useState, Fragment, useRef, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PageHeading from "../../components/PageHeading/PageHeading";
import ExtensionCard from "./ExtensionCard";
import "./Extensions.scss";
import Input from "../../components/Input/Input";
import Btn from "../../components/Button/Button";
import { Grid } from "@material-ui/core";
import { useAppContext } from "../../AppContext/App.context";
import useApiService from "../../services/api.service";
import useToast from "../../components/Toast/hooks/useToast";
import { extensionService } from "./Extensions.endpoints";
import CommonEndpoints from "../../services/commonApi.service";
import TwilioDetails from "./TwilioDetails";
import { Subject } from "rxjs";
import AlertDialog from "../../components/Dialog/Dialog";
const subject = new Subject();

export const extensionSubject = {
  subscribe: (update: any) => subject.subscribe(update),
  unsubscribe: () => subject.unsubscribe(),
  emit: (extensionData: any) => {
    subject.next(extensionData);
  },
};
const ExtensionDetail = () => {
  let params: any = useParams();
  const [extension, setExtension]: any = useState({});
  const [isActivated, setIsActivated] = useState(false);
  const [appData]: any = useAppContext();
  const ApiService = useApiService();
  const toaster = useToast();
  const [isLoading, setIsLoading]: any = useState(true);
  const access_token = appData.loginData.access_token;
  const twilioDetailsRef: any = useRef();
  const deactivateExtensionRef: any = useRef();
  const activateExtensionRef: any = useRef();
  useEffect(() => {
    //  setShown(true)
    if (params.extensionId) {
      getExtensionDetails(params.extensionId);
    } else {
      //setIsLoading(false);
    }
  }, []);
  let action_type = [
    {
      key: 0,
      value: "internal",
    },
    {
      key: 1,
      value: "external",
    },
  ];
  const [extensionType, setExtensionType]: any = useState();

  const getExtensionDetails = (id: any) => {
    // setExtension({
    //   id: 1,
    //   logo:
    //     "https://network-on.s3.ap-south-1.amazonaws.com/resource/42596916461617372244480-Screenshot%2020210326%20at%2011.07.51%20AM.png",
    //   name: "Route Optimization",
    //   detail: "minimize your optimization cost with optimized option",
    //   type: 1,
    //   description:
    //     "minimize your optimization cost with optimized optionminimize your optimization cost with optimized optionminimize your optimization cost with optimized option  optionminimize your optimization cost with optimized option  optionminimize your optimization cost with optimized option  optionminimize your optimization cost with optimized option",
    // });
    let header: any = {
      access_token: access_token,
    };
    let request: any = {
      extension_id: id,
    };
    let reqData = {
      reqBody: request,
      headers: header,
    };

    ApiService.get(extensionService.getExtensionsDetails(reqData))
      .then(function (response: any) {
        console.log(response);
        if (response.data[0].is_active === 1) {
          setIsActivated(true);
        }
        setExtension(response.data[0]);
        if (response.data[0]["action_type"] === 1) {
          setExtensionType(1);
        }
        if (response.data[0]["action_type"] === 2) {
          setExtensionType(2);
        }
        setIsLoading(false);
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  const getActivatedExtension = (token?: any) => {
    let header: any = {
      access_token: appData.loginData.access_token,
    };
    let request: any = {
      limit: 1000,
      skip: 0,
      user_id: appData.loginData.user_id,
    };
    let reqData = {
      reqBody: request,
      headers: header,
    };
    let data: any = [];
    ApiService.get(CommonEndpoints.activatedExtensionList(reqData))
      .then(function (response: any) {
        response.data.map((item: any) => {
          data.push(item.extension_id);
        });

        localStorage.setItem("activatedExtension", JSON.stringify(data));
      })
      .catch((err: any) => {});
  };
  const hubspotDeal = async (description: any) => {
    let header: any = {
      access_token: appData.loginData.access_token,
    };
    let phone = "";
    let request: any = {
      name: appData.loginData.name,
      email: appData.loginData.email,
      phone: Number(phone),
      description: description,
      // user_id: appData.loginData.user_id,
    };
    let reqData = {
      reqBody: request,
      headers: header,
    };
    await ApiService.post(extensionService.hubspotDeal(reqData))
      .then(function (response: any) {
        toaster.addToast({
          message: "We will contact you shortly!",
          timeout: 3000,
          type: "success",
        });
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  const handleTwilioActivation = () => {
    getActivatedExtension();
    setIsActivated(true);
  };
  const onActivateDiscard = () => {
    activateExtensionRef.current.handleClose();
  };
  const onActivateConfirm = () => {
    let header: any = {
      access_token: access_token,
    };
    let request: any = {
      extension_id: extension.extension_id,
    };
    request.price = extension.price;
    let reqData = {
      reqBody: request,
      headers: header,
    };
    ApiService.post(extensionService.activateExtension(reqData, 0))
      .then(function (response: any) {
        toaster.addToast({
          message: response.message,
          timeout: 3000,
          type: "success",
        });
        getActivatedExtension();
        setIsActivated(true);
        extensionSubject.emit({
          action: "activated",
          id: extension.extension_id,
        });
        activateExtensionRef.current.handleClose();
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };
  const onDeactivateDiscard = () => {
    deactivateExtensionRef.current.handleClose();
  };
  const onDeactivateConfirm = () => {
    let header: any = {
      access_token: access_token,
    };
    let request: any = {
      extension_id: extension.extension_id,
    };
    let reqData = {
      reqBody: request,
      headers: header,
    };
    if (extension.extension_id === 5) {
      ApiService.put(extensionService.deactivateTwilio(reqData))
        .then(function (response: any) {
          toaster.addToast({
            message: response.message,
            timeout: 3000,
            type: "success",
          });
          getActivatedExtension();
          setIsActivated(false);
          extensionSubject.emit({
            action: "deactivated",
            id: extension.extension_id,
          });
          deactivateExtensionRef.current.handleClose();
        })
        .catch((err: any) => {
          toaster.addToast({
            message: err.message,
            timeout: 3000,
            type: "error",
          });
        });
      return;
    }
    ApiService.put(extensionService.deactivateExtension(reqData))
      .then(function (response: any) {
        toaster.addToast({
          message: response.message,
          timeout: 3000,
          type: "success",
        });
        getActivatedExtension();
        setIsActivated(false);
        extensionSubject.emit({
          action: "deactivated",
          id: extension.extension_id,
        });
        deactivateExtensionRef.current.handleClose();
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message,
          timeout: 3000,
          type: "error",
        });
      });
  };

  return (
    <>
      {isLoading ? (
        ""
      ) : (
        <div style={{ width: "100%" }}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "40%",
                  flexDirection: "column",
                }}
              >
                <img
                  className={"extension__detailLogo"}
                  src={extension.icon}
                  alt="user icon"
                ></img>

                <div className={"extension__detailAction"}>
                  <div
                    style={{ margin: "10px 0px" }}
                    className={"extension__detailName"}
                  >
                    {extension.name}
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    {extension.caption}
                  </div>
                  {extensionType === 1 ? (
                    <Btn
                      className={"extension__detailActionBtn"}
                      onClick={() => {
                        if (extension.extension_id === 5 && !isActivated) {
                          twilioDetailsRef.current.handleOpen();
                        } else {
                          if (extensionType === 1) {
                            if (!isActivated) {
                              activateExtensionRef.current.handleClickOpen();
                            }
                            if (isActivated) {
                              deactivateExtensionRef.current.handleClickOpen();
                            }
                          }
                        }
                      }}
                      color={"primary"}
                      type={"button"}
                      btnText={!isActivated ? "Activate" : "Deactivate"}
                    ></Btn>
                  ) : (
                    <div>
                      <Btn
                        className={"extension__detailActionBtn"}
                        color={"primary"}
                        type={"button"}
                        onClick={() => {
                          hubspotDeal({
                            extension_id: extension.extension_id,
                            extension_name: extension.name,
                          });
                        }}
                        btnText={"Get Into Touch"}
                      ></Btn>
                      {/* <a className="extension__mailButton" href="mailto:info@networkon.io">GET INTO TOUCH</a> */}
                    </div>
                  )}
                </div>
              </div>

              <div style={{ marginRight: "10%" }}>
                <img
                  className={"extension__detailImage"}
                  src={extension.image}
                  alt="user icon"
                ></img>
              </div>
            </div>
            <div style={{ marginLeft: "30px" }}>
              <div className={"extension__description"}>Description</div>
              <div>{extension.description}</div>
            </div>
          </div>
          <Suspense fallback={<h3>Loading&hellip;</h3>}>
            <AlertDialog
              ref={deactivateExtensionRef}
              description={
                extension.extension_id === 5
                  ? "Are you sure you want to deactivate? By deactivating, customers and resources will not be able to view SMS notifications."
                  : "Are you sure you want to deactivate?"
              }
              title={"Deactivate Extension"}
              confirm={"Confirm"}
              discard={"Discard"}
              onConfirm={onDeactivateConfirm}
              onDiscard={onDeactivateDiscard}
            ></AlertDialog>
          </Suspense>
          <Suspense fallback={<h3>Loading&hellip;</h3>}>
            <AlertDialog
              ref={activateExtensionRef}
              description={"Are you sure you want to activate this extension?"}
              title={"Activate Extension"}
              confirm={"Confirm"}
              discard={"Discard"}
              onConfirm={onActivateConfirm}
              onDiscard={onActivateDiscard}
            ></AlertDialog>
          </Suspense>
          <Suspense fallback={<h3>Loading&hellip;</h3>}>
            <TwilioDetails
              ref={twilioDetailsRef}
              price={extension.price}
              handleTwilioActivation={handleTwilioActivation}
            ></TwilioDetails>
          </Suspense>
        </div>
      )}
    </>
  );
};

export default ExtensionDetail;
