import React, { useState, Fragment, useEffect } from "react";
import clsx from "clsx";
import Header from "../Header/Header";
import "./HeaderSidebarWrapper.module.scss";
import icon_to_close_sidebar from "../../assets/images/icon_to_close_sidebar.svg";
import icon_to_open_sidebar from "../../assets/images/open-side-menu.svg";
import smallLogo from "../../assets/images/inner-short-logo.jpeg";
import largeLogo from "../../assets/images/logo.svg";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import TextFieldsOutlinedIcon from "@material-ui/icons/TextFieldsOutlined";
import NotificationsIcon from "@material-ui/icons/NotificationsActive";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import TimelineIcon from "@material-ui/icons/Timeline";
import PieChartIcon from "@material-ui/icons/PieChart";
import LanguageIcon from "@material-ui/icons/Language";
import PermContactCalendarOutlinedIcon from "@material-ui/icons/PermContactCalendarOutlined";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import ExtensionOutlinedIcon from "@material-ui/icons/ExtensionOutlined";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import MapIcon from "@material-ui/icons/Map";
import { useHistory, useLocation } from "react-router";
import headerDropdownService from "../../services/headerDropdown.service";

import VpnKeyIcon from "@material-ui/icons/VpnKey";
import useLocalStorage from "../../utils/localStorage";
import { useTranslation } from "react-i18next";
import { extensionSubject } from "../../containers/Extensions/ExtensionDetail";
import { useAppContext } from "../../AppContext/App.context";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    boxShadow: "0 0 5px 0 #e5e9f2",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "#ffffff",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  fakeMenuButton: {
    "&:hover, &:focus": {
      background: "none",
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  largeLogo: {
    width: "200px",
    padding: "10px",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  selected: {
    // height: "70px",
    "&.Mui-selected": {
      color: "#fff",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      width: "calc(100% + 15px)",
      background: "#3c7bf6",
      "& svg": {
        color: "#fff",
      },
      "&:hover, &:focus": {
        background: "#3c7bf6",
        color: "#fff",
      },
    },
  },
  floatingIcon: {
    position: "absolute",
    bottom: "25px",
  },
  list: {
    height: "calc(100vh - 160px)",
  },
  subCategoryIcon: {
    marginLeft: "10px",
  },
  subCategoryList: {
    background: "#e3e3e34a",
  },
}));
// const ListItem1 = withStyles({
//   root: {
//     "&$selected": {
//       backgroundColor: "#3c7bf6",
//       color: "#fff"
//     },
//     "&$selected:hover": {
//       backgroundColor: "#3c7bf6",
//       color: "#fff"
//     }
//   },
//   selected: {}
// })(MuiListItem);
const HeaderSidebarWrapper = (props: any) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation(["Sidebar"]);

  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [settingsListOpen, setSettingsListOpen] = useState(false);
  const [analyticsListOpen, setAnalyticsListOpen] = useState(false);
  const [showRecurring, setShowRucurring]: any = useState(true);
  const location = useLocation();
  const history = useHistory();
  const [appData]: any = useAppContext();
  const [isAdmin, setIsAdmin]: any = useState(true);
  const [showBookingForm, setShowBookingForm]: any = useState(false);
  const Permission: any = useLocalStorage.getItem("Permission");
  let APIKey: any = true;
  let PermissionsArr: any = Permission ? Object.values(Permission) : [];
  if (PermissionsArr.length) {
    PermissionsArr.map((val: any) => {
      if (!val) {
        APIKey = false;
        return;
      }
    });
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const goToHome = () => {};
  const openCretaeTaskFromSidebar = () => {
    const dropdownData = {
      type: "clickedOnCreateTask",
      data: { clicked: true },
    };
    headerDropdownService.emit(dropdownData);
  };
  useEffect(() => {
    let headerSubscription = headerDropdownService.subscribe(eventFromService);
    console.log(headerSubscription);
    return () => {
      headerSubscription.unsubscribe();
    };
  }, []);
  const [enpType, setEnpType]: any = useState();
  const eventFromService = (eventData: any) => {
    console.log(eventData.type);
    switch (eventData.type) {
      case "earning":
        setEnpType(0);
        break;
      case "pricing":
        setEnpType(1);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getActivatedExtensions();
    let extensionSubscription: any = extensionSubject.subscribe((res: any) => {
      if (res.action === "activated" && res.id === 3) {
        setShowRucurring(true);
      } else if (res.action === "deactivated" && res.id === 3) {
        setShowRucurring(false);
      } else if (res.action === "activated" && res.id === 6) {
        setShowBookingForm(true);
      } else if (res.action === "deactivated" && res.id === 6) {
        setShowBookingForm(false);
      }
    });

    checkAdmin();
    return () => {
      extensionSubscription.unsubscribe();
    };
  }, []);
  const checkAdmin = () => {
    if (appData.loginData.user_id === appData.loginData.admin_id) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  };

  const getActivatedExtensions = () => {
    if (localStorage.getItem("activatedExtension") !== null) {
      let data: any = localStorage.getItem(`activatedExtension`);
      let activatedExtension: any = JSON.parse(data);

      if (!activatedExtension.includes(3)) {
        setShowRucurring(false);
      }
      if (activatedExtension.includes(6)) {
        setShowBookingForm(true);
      }
    }
  };

  return (
    <Fragment>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={goToHome}
            edge="start"
            className={clsx(classes.menuButton, classes.fakeMenuButton, {
              [classes.hide]: open,
            })}
          >
            <img style={{ width: "35px" }} src={smallLogo} />
          </IconButton>
          <Header></Header>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {/* <IconButton onClick={handleDrawerClose}>

            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton> */}
          <img src={largeLogo} className={classes.largeLogo} />
          <img src={icon_to_close_sidebar} onClick={handleDrawerClose} />
        </div>

        <List className={classes.list}>
          <ListItem>
            <ListItemIcon>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <img src={icon_to_open_sidebar} />
              </IconButton>
            </ListItemIcon>
          </ListItem>
          {/* <ListItem
            onClick={() => {
              history.push("/dashboard");
            }}
            className={classes.selected}
            button
            key="Dashboard"
            selected={location.pathname == "/dashboard"}
          >
            <ListItemIcon>
              <img  
              src={dashboard_sidebar_icon}
              alt="header logo" classes={classes}
            />
          <DashboardOutlinedIcon color={"action"}></DashboardOutlinedIcon>
            </ListItemIcon>
          {open ? <ListItemText primary={t("Sidebar:Dashboard")} /> : ""}
          </ListItem> */}
          {Permission && Permission["Read_Jobs"] ? (
            <ListItem
              onClick={() => {
                history.push("/jobs/list");
              }}
              selected={location.pathname.includes("/jobs/")}
              className={classes.selected}
              button
              key="Jobs"
            >
              <ListItemIcon>
                <AssignmentOutlinedIcon
                  color={"action"}
                ></AssignmentOutlinedIcon>
                {/* <img src={task_sidebar_icon} alt="task logo" /> */}
              </ListItemIcon>
              {open ? <ListItemText primary={t("Sidebar:Jobs")} /> : ""}
            </ListItem>
          ) : (
            ""
          )}
          {Permission && Permission["Read_Resources"] ? (
            <ListItem
              onClick={() => {
                history.push("/resources/list");
              }}
              className={classes.selected}
              selected={location.pathname.includes("/resources/")}
              button
              key="Resources"
            >
              <ListItemIcon>
                <PersonOutlineOutlinedIcon
                  color={"action"}
                ></PersonOutlineOutlinedIcon>
                {/* <img src={ResourceSidebarIcon} alt="resource logo" /> */}
                {/* <ResourceSidebarIcon></ResourceSidebarIcon> */}
              </ListItemIcon>
              {open ? <ListItemText primary={t("Sidebar:Resources")} /> : ""}
            </ListItem>
          ) : (
            ""
          )}
          {Permission && Permission["Read_Customers"] ? (
            <ListItem
              onClick={() => {
                history.push("/patients/list");
              }}
              className={classes.selected}
              selected={location.pathname.includes("/patients/")}
              button
              key="Customers"
            >
              <ListItemIcon>
                <PermContactCalendarOutlinedIcon
                  color={"action"}
                ></PermContactCalendarOutlinedIcon>
              </ListItemIcon>
              {open ? <ListItemText primary={t("Sidebar:Patients")} /> : ""}
            </ListItem>
          ) : (
            ""
          )}
          <ListItem
            onClick={() => {
              setAnalyticsListOpen(!analyticsListOpen);
            }}
            className={classes.selected}
            button
            key="Analytics"
          >
            <ListItemIcon>
              <TimelineIcon color={"action"}></TimelineIcon>
              {/* <img src={settings_sidebar_icon} alt="task logo" /> */}
            </ListItemIcon>
            {open ? <ListItemText primary={t("Sidebar:Analytics")} /> : null}
            {open ? analyticsListOpen ? <ExpandLess /> : <ExpandMore /> : null}
          </ListItem>
          <Collapse
            className={classes.subCategoryList}
            in={analyticsListOpen}
            timeout="auto"
            unmountOnExit
          >
            <List>
              <ListItem
                onClick={() => {
                  history.push("/analytics/overview");
                }}
                className={classes.selected}
                selected={location.pathname.includes("/analytics/overview")}
                button
                key="Overview"
              >
                <ListItemIcon className={classes.subCategoryIcon}>
                  <PieChartIcon color={"action"}></PieChartIcon>
                </ListItemIcon>
                {open ? (
                  <ListItemText primary={t("Sidebar:Patient Dashboard")} />
                ) : (
                  ""
                )}
              </ListItem>

              <ListItem
                onClick={() => {
                  history.push("/analytics/shiftdashboard");
                }}
                className={classes.selected}
                selected={location.pathname.includes(
                  "/analytics/shiftdashboard"
                )}
                button
                key="shift-dashboard"
              >
                <ListItemIcon className={classes.subCategoryIcon}>
                  {/* <PieChartIcon color={"action"}></PieChartIcon> */}
                  <AssignmentOutlinedIcon
                    color={"action"}
                  ></AssignmentOutlinedIcon>
                </ListItemIcon>
                {open ? (
                  <ListItemText primary={t("Sidebar:Shift Dashboard")} />
                ) : (
                  ""
                )}
              </ListItem>
            </List>
          </Collapse>
          {/* <ListItem className={classes.selected} button key="Analytics">
            <ListItemIcon>
              <img src={analytics_sidebar_icon} alt="task logo" />
            </ListItemIcon>
            {open ? <ListItemText primary="Analytics" /> : ""}
          </ListItem> */}
          {Permission &&
          (Permission["Read_Teams"] ||
            Permission["Read_Supervisors"] ||
            Permission["Read_Autoallocation"] ||
            Permission["Read_Geofence"] ||
            Permission["Read_Templates"] ||
            Permission["Read_Roles"]) ? (
            <ListItem
              onClick={() => {
                setSettingsListOpen(!settingsListOpen);
              }}
              className={classes.selected}
              button
              key="Settings"
            >
              <ListItemIcon>
                <SettingsOutlinedIcon color={"action"}></SettingsOutlinedIcon>
                {/* <img src={settings_sidebar_icon} alt="task logo" /> */}
              </ListItemIcon>
              {open ? <ListItemText primary={t("Sidebar:Settings")} /> : null}
              {open ? settingsListOpen ? <ExpandLess /> : <ExpandMore /> : null}
            </ListItem>
          ) : (
            ""
          )}
          <Collapse
            className={classes.subCategoryList}
            in={settingsListOpen}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {Permission && Permission["Read_Teams"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/teams")}
                  onClick={() => {
                    history.push("/settings/teams");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <GroupOutlinedIcon color={"action"}></GroupOutlinedIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? <ListItemText primary={t("Sidebar:Teams")} /> : ""}
                </ListItem>
              ) : (
                ""
              )}
              {Permission && Permission["Read_Supervisors"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/supervisors")}
                  onClick={() => {
                    history.push("/settings/supervisors");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <SupervisedUserCircleOutlinedIcon
                      color={"action"}
                    ></SupervisedUserCircleOutlinedIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Supervisors")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
              {Permission && Permission["Read_Autoallocation"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes(
                    "/settings/autoallocation"
                  )}
                  onClick={() => {
                    history.push("/settings/autoallocation");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <AutorenewIcon color={"action"}></AutorenewIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Auto Allocation")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
              {Permission && Permission["Read_Resources"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes(
                    "/settings/resourceschedule"
                  )}
                  onClick={() => {
                    history.push("/settings/resourceschedule");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <ScheduleOutlinedIcon
                      color={"action"}
                    ></ScheduleOutlinedIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Resource Schedule")} />
                  ) : null}
                </ListItem>
              ) : null}
              {isAdmin ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/customerapp")}
                  onClick={() => {
                    history.push("/settings/customerapp");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <AutorenewIcon color={"action"}></AutorenewIcon>
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Customer App")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}

              {Permission && Permission["Read_Geofence"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/geofence")}
                  onClick={() => {
                    history.push("/settings/geofence");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <MapIcon color={"action"}></MapIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? <ListItemText primary={t("Sidebar:Geofence")} /> : ""}
                </ListItem>
              ) : (
                ""
              )}
              {Permission && Permission["Read_Templates"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/templates")}
                  onClick={() => {
                    history.push("/settings/templates/list");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <TextFieldsOutlinedIcon
                      color={"action"}
                    ></TextFieldsOutlinedIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Templates")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
              {Permission && Permission["Read_Roles"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/roles")}
                  onClick={() => {
                    history.push("/settings/roles");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <AutorenewIcon color={"action"}></AutorenewIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? <ListItemText primary={t("Sidebar:Roles")} /> : ""}
                </ListItem>
              ) : (
                ""
              )}
              {Permission && !Permission["Read_Language"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes(
                    "/settings/language/changelanguage"
                  )}
                  onClick={() => {
                    history.push("/settings/language/changelanguage");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <LanguageIcon color={"action"}></LanguageIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Language Manager")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
              {APIKey ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/apikey")}
                  onClick={() => {
                    history.push("/settings/apikey");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <VpnKeyIcon color={"action"}></VpnKeyIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? <ListItemText primary={t("Sidebar:API Key")} /> : ""}
                </ListItem>
              ) : (
                ""
              )}

              {Permission && Permission["Read_Eventsettings"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes(
                    "/settings/notifications"
                  )}
                  onClick={() => {
                    history.push("/settings/notifications");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <NotificationsIcon color={"action"}></NotificationsIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Notifications")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
              {showRecurring ? (
                Permission && Permission["Read_Jobs"] ? (
                  <ListItem
                    className={classes.selected}
                    button
                    selected={location.pathname.includes("/settings/recurring")}
                    onClick={() => {
                      history.push("/settings/recurring");
                    }}
                  >
                    <ListItemIcon className={classes.subCategoryIcon}>
                      <AutorenewIcon color={"action"}></AutorenewIcon>
                      {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                    </ListItemIcon>
                    {open ? (
                      <ListItemText primary={t("Sidebar:Recurring Rules")} />
                    ) : (
                      ""
                    )}
                  </ListItem>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {Permission && Permission["Read_Templates"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/resourceapp")}
                  onClick={() => {
                    history.push("/settings/resourceapp");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <PhoneIphoneIcon color={"action"}></PhoneIphoneIcon>
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Sidebar:Resource App")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
              {Permission && Permission["Read_Eventsettings"] ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={
                    location.pathname.includes("/settings/earning") ||
                    location.pathname.includes("/settings/pricing")
                  }
                  onClick={() => {
                    enpType === 0
                      ? history.push("/settings/pricing")
                      : history.push("/settings/earning");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <AttachMoneyIcon color={"action"} />
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Common:Earning Pricing")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
              {isAdmin ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/billing")}
                  onClick={() => {
                    history.push("/settings/billing");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <CreditCardOutlinedIcon color={"action"} />
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? <ListItemText primary={t("Common:Billing")} /> : ""}
                </ListItem>
              ) : null}
              {isAdmin && showBookingForm ? (
                <ListItem
                  className={classes.selected}
                  button
                  selected={location.pathname.includes("/settings/bookingform")}
                  onClick={() => {
                    history.push("/settings/bookingform");
                  }}
                >
                  <ListItemIcon className={classes.subCategoryIcon}>
                    <BusinessCenterIcon color={"action"} />
                    {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
                  </ListItemIcon>
                  {open ? (
                    <ListItemText primary={t("Common:Booking Forms")} />
                  ) : (
                    ""
                  )}
                </ListItem>
              ) : (
                ""
              )}
            </List>
          </Collapse>
          {/* {Permission &&
          Permission["Read_Jobs"] &&
          Permission["Read_Resources"] ? (
            <ListItem
              onClick={() => {
                history.push("/scheduler/");
              }}
              className={classes.selected}
              selected={location.pathname.includes("/scheduler/")}
              button
              key="scheduler"
            >
              <ListItemIcon>
                <AssignmentOutlinedIcon
                  color={"action"}
                ></AssignmentOutlinedIcon>
              </ListItemIcon>
              {open ? <ListItemText primary={t("Sidebar:Scheduler ")} /> : ""}
            </ListItem>
          ) : null} */}

          {isAdmin ? (
            <ListItem
              onClick={() => {
                history.push("/extensions/list");
              }}
              className={classes.selected}
              selected={location.pathname.includes("/extensions/")}
              button
              key="Extensions"
            >
              <ListItemIcon>
                <ExtensionOutlinedIcon color={"action"}></ExtensionOutlinedIcon>
              </ListItemIcon>
              {open ? <ListItemText primary={t("Sidebar:Extensions")} /> : ""}
            </ListItem>
          ) : (
            ""
          )}
          <ListItem
            onClick={() => {
              history.push("/chat");
            }}
            className={classes.selected}
            selected={location.pathname.includes("/chat")}
            button
            key="Chat"
          >
            <ListItemIcon>
              <ChatBubbleOutlineOutlinedIcon
                color={"action"}
              ></ChatBubbleOutlineOutlinedIcon>
            </ListItemIcon>
            {open ? <ListItemText primary={t("Sidebar:Chat")} /> : ""}
          </ListItem>
        </List>
      </Drawer>
      {/* <Header></Header>
      <SideMenu></SideMenu> */}
    </Fragment>
  );
};
export default HeaderSidebarWrapper;

// {TabPermission && TabPermission[RolePermissions.Read_APIKey] ? (
//   <ListItem
//   className={classes.selected}
//   button
//   selected={location.pathname.includes("/settings/apikey")}
//   onClick={() => {
//     history.push("/settings/apikey");
//   }}
// >
//   <ListItemIcon>
//     <VpnKeyIcon
//       color={"action"}
//     ></VpnKeyIcon>
//     {/* <img src={TeamDropdownIcon} alt="team drpdown"></img> */}
//   </ListItemIcon>
//   {open ? <ListItemText primary="API Key" /> : ""}
// </ListItem>
// ): ( "" )}
