import React, { useEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";

import cross from "../../assets/images/cross.svg";
// import tick from "../../assets/images/tick.svg";
import "./Input.scss";
//import { Trans, useTranslation } from "react-i18next";

import { useTranslation } from "react-i18next";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "10px",
    "& .MuiTextField-root": {
      margin: theme.spacing(10),
    },
  },
  // input: {
  //   color: "white"
  // }
}));
const Input: React.FC<any> = (props) => {
  // const { t } = useTranslation(["Input"]);

  const { t, i18n } = useTranslation(["Common"]);

  const [disable, setDisable] = useState(false);
  const label = props.label;
  const classes = useStyles();
  useEffect(() => {
    if (props.disable) setDisable(props.disable);
  }, [props.disable]);

  // useEffect(() => {
  //   console.log(props.errors)
  // }, [props.errors]);

  const onChangeHandler = (ev: any) => {
    if (!props.onchange) {
      return;
    }
    props.onchange(ev);
  };

  return (
    <React.Fragment>
      <div className="input" style={props.styling}>
        {/* <label className="input__title py-2" style={props.labelStyle}>
          {t(`Input:${label}`, label)}
          {props.required ? <span className="required"> {"*"}</span> : null}
        </label> */}
        <div className="input__box-container">
          <TextField
            className={classes.root}
            id={props.id ? props.id : null}
            label={t(`Common: ${props.label ? props.label : ""}`)}
            inputRef={props.refValue}
            defaultValue={props.value}
            onBlur={props.onBlur}
            // value={props.value}
            type={props.type ? props.type : null}
            fullWidth={true}
            disabled={props.disabled ? props.disabled : false}
            required={props.required ? props.required : false}
            name={props.id ? props.id : null}
            // InputLabelProps={props.refValue?{shrink:true}:{} }
            InputLabelProps={props.InputLabelProps}
            // InputProps={props.InputProps ? props.InputProps : null}
            InputProps={{
              ...props.InputProps,
              // className: classes.input,
              style: { borderRadius: "10px" },
              autoComplete: "off",
            }}
            autoComplete={props.id ? props.id : null}
            multiline={props.multiline || null}
            rows={props.rows || 4}
            variant={props.variant || "outlined"}
            onChange={onChangeHandler}
            helperText={props.helperText}
            error={
              props.errors ? (props.errors[props.name] ? true : false) : false
            }
            onKeyPress={
              props.onkeypress
                ? (evt: any) => {
                    var theEvent: any = evt || window.event;
                    var key = theEvent.keyCode || theEvent.which;
                    key = String.fromCharCode(key);
                    var regex = /[0-9]|\./;
                    if (!regex.test(key)) {
                      theEvent.returnValue = false;
                      if (theEvent.preventDefault) theEvent.preventDefault();
                    }
                    props.onkeypress(evt);
                  }
                : () => {}
            }
          />
          {/* <input
            id={props.id ? props.id : null}
            className={
              props.errors[props.name]
                ? "input__box-error"
                : props.inputFocus?.[props.name]
                ? "input__box-error"
                : "input__box"
            }
            disabled={disable}
            type={props.type}
            name={props.name}
            ref={props.refValue}
            onFocus={() => {
              if (props.setInputFocus) {
                props.setInputFocus(props.name);
              } else {
                return;
              }
            }}
            placeholder={props.placeholder ? props.placeholder : props.label}
            defaultValue={props.defaultValue}
          ></input> */}
          {
            props.errors ? (
              props.errors[props.name] ? (
                <span className="input__cross">
                  {/* <img src={cross} alt="cross"></img> */}
                </span>
              ) : null
            ) : null
            // props.showErrormsg ? null : (
            //   <span className="input__cross">
            //     <img src={tick} alt="tick"></img>
            //   </span>
          }
          {props.errors ? (
            <div className="input__errormsg">
              <ErrorMessage
                errors={props.errors}
                name={props.name}
                render={({ message }) => <div>{message}</div>}
              />
            </div>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Input;
