import React, { useEffect } from "react";
import "./Dropdown.modules.scss";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

interface IModalProps {
  onClose?: any;
  className?: string;
  selectedValue?: any;
  onChange?: any;
}
const Dropdown: React.FC<any> = (props) => {
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const onChangeDropdown = (event: any) => {
    if (!props.onChange) {
      return false;
    }
    props.onChange(event);
  };
  const renderTeamList = (itemList: any) => {
    return (
      itemList.length > 0 &&
      itemList.map((item: any, index: any) => {
        return (
          <MenuItem value={item[props.uniqueId]}>
            <div className={"textExceed"}> {item[props.label]}</div>
          </MenuItem>
        );
      })
    );
  };

  return (
    <React.Fragment>
      <FormControl
        variant="outlined"
        fullWidth={true}
        style={{ marginBottom: "10px" }}
      >
        <InputLabel id="demo-controlled-open-select-label">
          {props.labelName}
        </InputLabel>
        <Select
          MenuProps={{
            disableScrollLock: true,
          }}
          ref={props.ref}
          className={props.className}
          labelId="demo-controlled-open-select-label"
          fullWidth={true}
          required={props.required ? props.required : false}
          name={props.name ? props.name : "demo-simple-select"}
          id={props.id ? props.id : "demo-simple-select"}
          variant={props.variant ? props.variant : "outlined"}
          value={props.selectedValue}
          onChange={onChangeDropdown}
          disabled={props.disabled ? props.disabled : false}
          disableUnderline={props.disableUnderline ? true : false}
        >
          {renderTeamList(props.itemList)}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default React.memo(Dropdown);
